import {mapFilters} from "@/utils/helpers";

const initState = () => ({
  orders: {
    total: 0,
    items: [],
    options: {},
  },
  orderNotes: [],
  orderItemImages: [],
  jobOrderItems: [],

})

export const state = initState

export const getters = {
}

export const mutations = {

  setOrderNotes(state, {notes}) {
    state.orderNotes = notes
  },

  setOrderItemImages(state, {images}) {
    state.orderItemImages = images
  },

  setJobImages(state, {jobImages}) {
    state.jobImages = jobImages
  },
}

export const actions = {

  async fetchOrderNotes({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/JobOrders/${jobID}/notes`)
    if (result) {
      commit('setOrderNotes', {notes: result})
    }
    return result
  },

  async saveOrderNote({commit, state}, {note, image, jobID}) {
    const formData = new FormData()
    formData.append('image', image)
    formData.append('note', note)
    formData.append('jobOrderID', jobID)
    return await this.$axios.$post(`/JobOrders/notes`, formData)

  },


  async fetchOrderItemImages({commit, state}, {jobID}) {
    const result = await this.$axios.$get(`/webpos/order/${jobID}/item-images/`)
    if (result && !result.hasError) {
      commit('setOrderItemImages', {images: result.value})
      return result.value
    }
    else{
      this.$toast.error(result.errorMessages[0])
    }
  },

  removeImages({commit, state}){
    commit('setOrderItemImages', {images: []})
  },


  //get the order items
  async fetchOrderItems({commit, state}, orderID) {
    const res = await this.$axios.get(`webpos/order/${orderID}/items`)
    if(res && res.data && !res.data.hasErrors){
      return res.data.value
    }
    else{
      this.$toast.error('Error occured while getting order detail');
      return [];
    }
  },

  async fetchCheckoutDetail({commit, state}, orderID) {
   try{
     const res = await this.$axios.get(`webpos/order/${orderID}/checkout-detail`)
     if(res && res.data && !res.data.hasErrors){
       return res.data.value
     }
     else{
       this.$toast.error('Error occured while getting order detail');
       return null
     }
   }
   catch{
     this.$toast.error('Error occured while getting order detail');
     return null
   }
  },

  async getInvoiceDetailForOrder({}, {orderID}) {
    try{
      const result = await this.$axios.$get(`/webpos/order/${orderID}/invoice-detail`);

      if(result && !result.hasErrors){
        return result.value;
      }
      else{
        this.$toast.error('Error while getting invoice details.')
        return {};
      }
    }
    catch{
      this.$toast.error('Error while getting invoice detail.')
      return {};
    }
  },

  async getJobTypeStatusTypes({}, {jobTypeCode}) {
    let statuses = []
    try{
      const url = `/webpos/order/${jobTypeCode}/statuses`;

      const result = await this.$axios.$get(url);

      if(result && !result.hasErrors){
        statuses = result.value;
      }
      else{
        this.$toast.error('Error while getting job type statuses')

      }
    }
    catch{
      this.$toast.error('Error while getting job type statuses')
    }

    return statuses;
  },

  async fetchOrderDetail({commit}, orderID) {
    let orderDetail = [];
    try{
      const result = await this.$axios.$get(`webpos/order/${orderID}/detail`);

      if(result && !result.hasErrors){
        orderDetail =  result.value;
      }
      else{
        this.$toast.error('Error while getting order details.')
      }
    }
    catch{
      this.$toast.error('Error while getting order details.')
    }
    return orderDetail;
  },

  async fetchOrderDetailByEncryptedId({commit}, encryptedID) {
    let orderDetail = [];
    try{
      const result = await this.$axios.$get(`webpos/order/by-encrypted-id/?encryptedID=${encodeURIComponent(encryptedID)}`);

      if(result && !result.hasErrors){
        orderDetail =  result.value;
      }
      else{
        this.$toast.error('Error while getting order details.')
      }
    }
    catch{
      this.$toast.error('Error while getting order details.')
    }
    return orderDetail;
  },

  async fetchOrderImages({commit, state}, {jobID}){
    let images = [];
    try{
      const result = await this.$axios.$get(`/webpos/order/${jobID}/images`)
      if (result && !result.hasError) {
        images = result.value;
      }
      else{
        this.$toast.error(result.errorMessages[0]);
      }
    } catch(error){
      this.$toast.error("Error while getting job images");
    }

    return images;
  },


  async fetchOrderHistory({},{filters, customerID}){
    let ordersHistory = null;
    try{
      let url = `/webpos/customers/${customerID}/orders?${new URLSearchParams(filters).toString()}`;

      if(filters.storeID > 0){
        url =`${url}&storeID=${filters.storeID}`
      }

      const result = await this.$axios.$get(url);

      if(result && !result.hasErrors){
        ordersHistory = result
      }
      else{
        this.$toast.error("Error while getting order history.")
      }

    }catch(e){
      this.$toast.error("Error while getting order history.")
    }
    return ordersHistory;
  },

  async getVariantIDBasedOnParentItemAttributes({}, {searchVariantData}){
    let searchVariantResult = [];
    try{
      const result = await this.$axios.$post(`/webpos/order/search-variants`, searchVariantData);

      if(!result || !result.apiMessages || result.apiMessages.hasErrors){
        this.$toast.error('Error while searching variants')
        return;
      }
      searchVariantResult = result.value;
    }
    catch{
      this.$toast.error('Error while searching variants')
    }

    return searchVariantResult;
  },

  async generateInvoice({commit}, order) {
    let generatedInvoiceCode = null

    try{
      const result = await this.$axios.$post(`/webpos/order/${order.id}/generate-invoice`)

      if (result && result.value) {
        generatedInvoiceCode = result.value;
        this.$toast.success(`Invoice with code: ${generatedInvoiceCode} generated successfully`)
      }
      else {
        this.$toast.error(`An error occurred while generating invoice for job '${order.code}'.`)
      }
    }
    catch (error) {
      this.$toast.error(`An error occurred while generating invoice for job '${order.code}'.`)
    }
    return generatedInvoiceCode
  },
}
